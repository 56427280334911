import { createApp } from 'vue'
import VueKonva from 'vue-konva';
import App from './App.vue'
import router from './router'
import store from './store'

import '@/assets/css/style.css'
import '@/assets/css/iconfont.css'

//引入权限逻辑文件
import '@/permission'

createApp(App).use(VueKonva).use(store).use(router).mount('#app')
