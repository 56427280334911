import { createStore } from 'vuex'
import getters from "./getters"
import client from './modules/client'
import sceneView from './modules/scene-view'

const store = {
    getters,
    modules: {
        client,
        sceneView
    }
}

export default createStore(store)
