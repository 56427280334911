<template>
    <div class="pd">
        <div class="wrap">
            <header class="navbar">
                <div class="navbar-wrapper">
                    <div class="logo-container">
                        <img src="~@/assets/images/logo.png" alt="logo">
                    </div>
                    <div class="content">
                        <nav class="navbar-menu">
                            <div class="menu-item" @click="takePhoto()" v-if="isCapture">拍照</div>
                            <div class="menu-item" @click="cancel()" v-if="isCapture || isShowBoard">取消</div>
                            <div class="menu-item" @click="showBoard()" v-if="sceneList.length > 0">查看已拍</div>
                            <div class="menu-item" @click="nextPhoto()" v-if="isShowBoard">下一张</div>
                            <div class="menu-item" @click="gotoQuote()">报价</div>
                        </nav>
                    </div>
                </div>
            </header>
            <section class="container">
                <div class="row">
                    <div class="col-16">
                        <div class="border-wrap" ref="borderWarpRef">
                            <KonvaBoard v-if="isShowBoard" v-bind:data="sceneList[currSceneIndex]"  />
                            <PhotoCapture ref="photoRef" v-else v-model:isCapture="isCapture" />
                        </div>
                    </div>
                    <div class="col-8">
                        <ProductList @rowClick="handleProductClick" />
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<style scoped>
.wrap{max-height: calc(100vh - 5rem);display: flex;flex-direction: column;}
.navbar-wrapper {display: flex;justify-content: space-between;margin-bottom: 2.5rem;}
.navbar-wrapper .logo-container img {max-width: 100%;height: auto;}
.navbar-wrapper .content {display: flex;flex-grow: 1;justify-content: flex-end;}
.navbar-menu {display: flex;gap: 1.25rem;}
.navbar-menu .menu-item {color: #ffffff;padding: 0 1rem;border-radius: 0.5rem;background-color: #cccccc;height: 2.5rem;line-height: 2.5rem;}

.border-wrap{height: 100%;border: 1px dashed #fff;}
</style>

<script setup>
import KonvaBoard from './components/KonvaBoard';
import ProductList from './components/ProductList';
import PhotoCapture from './components/PhotoCapture';

import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

const store = useStore();
const router = useRouter();

const borderWarpRef = ref(null);

const photoRef = ref(null);

const isShowBoard = ref(false);

const isCapture = ref(false);

const konvaSize = ref({
    width: 0,
    height: 0
})

const currSceneIndex = computed(() => store.state.sceneView.currentSceneIndex);

const sceneList = computed(() => store.state.sceneView.scenes);

const showBoard = () => {
    isShowBoard.value = true;
}

const cancel = () => {
    isCapture.value = false;
    isShowBoard.value = false;
}

const takePhoto = () => {
    photoRef.value.takePhoto();
}

const handleProductClick = (data) => {
    if (isShowBoard.value == false) return;
    if (sceneList.value.length <= 0) return;

    const centerX = konvaSize.value.width / 2 - 25;
    const centerY = konvaSize.value.height / 2 - 25;
    const index = currSceneIndex.value;

    let products = store.state.sceneView.products;
    let productIndex = products.findIndex(item => item.id === data.id);

    // 判断是否已经存在，存在数量+1，不在则添加   
    if (productIndex !== -1) {
        products[productIndex] = {
            ...products[productIndex],
            qty: (products[productIndex].qty || 0) + 1
        }
    } else {
        products.push({
            ...data,
            qty: 1
        }); 
    }

    // 添加图像
    const imageObj = new Image()
    imageObj.src = data.image;
    imageObj.onload = () => {
        sceneList.value[index].data.push({
            rotation: 0,
            x: centerX,
            y: centerY,
            width: 50,
            height: 50,
            scaleX: 1,
            scaleY: 1,
            image: imageObj,
            name: `image-${Date.now()}`,
            draggable: true
        });
    }  
}

const nextPhoto = () => {
    if (sceneList.value.length > 0) {
        const currentIndex = currSceneIndex.value;
        // 计算下一张照片的索引（循环）
        const nextIndex = (currentIndex + 1) % sceneList.value.length;

        // 切换场景
        store.dispatch('setCurrentScene', nextIndex);
    }
}

const gotoQuote = () => {
    router.push('/quote');
}

onMounted(() => {
    konvaSize.value.width = borderWarpRef.value.offsetWidth;
    konvaSize.value.height = borderWarpRef.value.offsetHeight;
})
</script>
