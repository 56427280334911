// store/modules/scene.js
export default {
    state: {
        scenes: [], // 场景列表，每个场景包含背景和产品
        products: [], // 可选择的产品列表
        currentSceneIndex: 0, // 当前场景索引
    },

    getters: {
        currentScene: state => state.scenes[state.currentSceneIndex],
        scenesCount: state => state.scenes.length,
    },

    mutations: {
        ADD_SCENE(state, scene) {
            state.scenes.push({
                background: scene.background || '',
                products: scene.products || [],
                data: scene.data || []
            });
        },

        UPDATE_SCENE(state, { index, scene }) {
            state.scenes[index] = {
                ...state.scenes[index],
                ...scene
            };
        },

        SET_SCENES(state, scenes) {
            state.scenes = scenes;
        },

        SET_CURRENT_SCENE(state, index) {
            state.currentSceneIndex = index;
        },

        ADD_PRODUCT_TO_SCENE(state, { sceneIndex, product }) {
            state.scenes[sceneIndex].products.push(product);
        },

        REMOVE_PRODUCT_FROM_SCENE(state, { sceneIndex, productIndex }) {
            state.scenes[sceneIndex].products.splice(productIndex, 1);
        },

        UPDATE_PRODUCT_IN_SCENE(state, { sceneIndex, productIndex, updates }) {
            const product = state.scenes[sceneIndex].products[productIndex];
            state.scenes[sceneIndex].products[productIndex] = {
                ...product,
                ...updates
            };
        },

        SET_PRODUCTS(state, products) {
            state.products = products;
        }
    },

    actions: {
        // 添加新场景
        addScene({ commit }, scene) {
            commit('ADD_SCENE', scene);
        },

        // 更新场景
        updateScene({ commit }, payload) {
            commit('UPDATE_SCENE', payload);
        },

        // 设置场景列表
        setScenes({ commit }, scenes) {
            commit('SET_SCENES', scenes);
        },

        // 切换当前场景
        setCurrentScene({ commit }, index) {
            commit('SET_CURRENT_SCENE', index);
        },

        // 向场景添加产品
        addProductToScene({ commit }, payload) {
            commit('ADD_PRODUCT_TO_SCENE', payload);
        },

        // 从场景移除产品
        removeProductFromScene({ commit }, payload) {
            commit('REMOVE_PRODUCT_FROM_SCENE', payload);
        },

        // 更新场景中的产品
        updateProductInScene({ commit }, payload) {
            commit('UPDATE_PRODUCT_IN_SCENE', payload);
        },

        // 设置可选产品列表
        setProducts({ commit }, products) {
            commit('SET_PRODUCTS', products);
        }
    }
};