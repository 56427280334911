<template>
    <div class="board-warp" ref="boardRef">
        <v-stage  ref="stage"  :config="{width: konvaSize.width, height: konvaSize.height}" @mousedown="handleStageMouseDown" @touchstart="handleStageMouseDown">
            <v-layer ref="layer">
                <v-image
                  :config="{
                    x: 0,
                    y: 0,
                    width: konvaSize.width,
                    height: konvaSize.height,
                    image: backgroundImage
                  }"
                />
                <v-image v-for="item in data.data" :key="item.id" :config="item" @transformend="handleTransformEnd" @dragend="handleDragEnd" />
                <v-transformer ref="transformer" :config="{
                    enabledAnchors: ['top-left', 'top-right', 'bottom-left', 'bottom-right'],
                    rotateEnabled: true,
                    borderEnabled: true,
                    keepRatio: true
                }"/>
            </v-layer>
        </v-stage>
    </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { ref, watch, computed, onMounted } from 'vue';

const store = useStore();

const data = computed(() => store.getters.currentScene);

const backgroundImage = ref(null);

const boardRef = ref(null);
const stage = ref(null)
const transformer = ref(null)

const konvaSize = ref({
    width: 0,
    height:0
})

const selectedShapeName = ref('')

const handleTransformEnd = (e) => {
    const currentData = data.value.data.map(item => {
        if (item.name === selectedShapeName.value) {
            return {
                ...item,
                x: e.target.x(),
                y: e.target.y(),
                rotation: e.target.rotation(),
                scaleX: e.target.scaleX(),
                scaleY: e.target.scaleY(),
                // 保持原始 image 对象不变
                image: item.image
            };
        }
        return item;
    });

    // 找到并更新被修改的图像
    const imageIndex = currentData.findIndex((i) => i.name === selectedShapeName.value);
    if (imageIndex !== -1) {
        currentData[imageIndex] = {
          ...currentData[imageIndex],
          x: e.target.x(),
          y: e.target.y(),
          rotation: e.target.rotation(),
          scaleX: e.target.scaleX(),
          scaleY: e.target.scaleY()
        };

        // 调用 store 的 updateScene 方法更新数据
        store.dispatch('updateScene', {
            index: store.state.sceneView.currentSceneIndex,
            scene: {
                ...data.value,
                data: currentData
            }
        });
    }
}

const updateTransformer = () => {
    const transformerNode = transformer.value.getNode()
    const stage = transformerNode.getStage()
    const selectedNode = stage.findOne('.' + selectedShapeName.value)

    if (selectedNode === transformerNode.node()) {
        return
    }

    if (selectedNode) {
        transformerNode.nodes([selectedNode])
    } else {
        transformerNode.nodes([])
    }
}

const handleStageMouseDown = (e) => {
    if (e.target === e.target.getStage()) {
        selectedShapeName.value = ''
        updateTransformer()
        return
    }

    const clickedOnTransformer = e.target.getParent().className === 'Transformer';
    if (clickedOnTransformer) {
        return
    }

    const name = e.target.name();
    const image = data.value.data.find((i) => i.name === name)
    if (image) {
        selectedShapeName.value = name
    } else {
        selectedShapeName.value = ''
    }
    updateTransformer()
}

const handleDragEnd = (e) => {
    const currentData = data.value.data.map(item => {
    if (item.name === e.target.name()) {
        return {
            ...item,
            x: e.target.x(),
            y: e.target.y()
        };
        }
        return item;
    });

    store.dispatch('updateScene', {
        index: store.state.sceneView.currentSceneIndex,
        scene: {
            ...data.value,
            data: currentData
        }
    });
}

watch(() => data.value.background, (newData) => {
    if (newData) {
         const image = new Image();
         image.onload = () => {
            backgroundImage.value = image;
        };
        image.src = newData;
    } else {
        backgroundImage.value = null;
    }
}, { immediate: true, deep: true });

onMounted(() => {
    konvaSize.value.width = boardRef.value.offsetWidth;
    konvaSize.value.height = boardRef.value.offsetHeight;
})
</script>

<style scoped>
.board-warp{width: 100; height: 100%;}
</style>