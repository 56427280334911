import router from '@/router'
import store  from "@/store"

//白名单
const whiteList = ['/login']

router.beforeEach(async(to, from, next) => {
    if (store.getters.client.token) {
        next();
    } else {
        if (whiteList.indexOf(to.path) !== -1) {
            next()
        } else {
            next({ path: '/login' })
        }
    }
})