import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/quote',
        name: 'quote',
        component: () => import('@/views/QuoteView.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/LoginView.vue')
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router
