<template>
    <div class="photo-warp">
        <video ref="videoRef" autoplay v-show="isCapture"></video>
        <div class="photo-content" v-show="!isCapture" @click="startCamera">
            <i class="iconfont icon-photo"></i>
        </div>
    </div>
</template>

<script setup>
import { ref, watch, nextTick } from 'vue';
import { useStore } from 'vuex';

// 导入音效文件
import cameraShutter from '@/assets/audio/camera-shutter.mp3';

// 创建音频对象
const audioRef = ref(new Audio(cameraShutter));

const props = defineProps({
    isCapture: {
        type: Boolean,
        default: false
    }
})

const emit = defineEmits(['update:isCapture']);

const store = useStore();

const videoRef = ref(null);
const stream = ref(null);

// 打开相机
const startCamera = async () => {
    try {
        const mediaStream = await navigator.mediaDevices.getUserMedia({
            video: {
                facingMode: 'environment' //使用后置摄像头
            },
            audio: false
        })
        stream.value = mediaStream;

        emit('update:isCapture', true);

        await nextTick();

        if (videoRef.value) {
            videoRef.value.srcObject = mediaStream;
        }
    } catch (error) {
        console.error('访问摄像头失败:', error)    
        alert(error);
    }
}

// 关闭相机
const closeCamera = () => {
    if (stream.value) {
        stream.value.getTracks().forEach(track => track.stop())
        stream.value = null
    }
    alert("我被关闭拉");
    emit('update:isCapture', false);
}

// 拍照
const takePhoto = () => {
    if (!videoRef.value) return;

    // 播放拍照音效
    audioRef.value.play();

    const canvas = document.createElement('canvas');
    canvas.width = videoRef.value.videoWidth;
    canvas.height = videoRef.value.videoHeight;

    const ctx = canvas.getContext('2d');
    if (ctx) {
        ctx.drawImage(videoRef.value, 0, 0);
        const photoUrl = canvas.toDataURL('image/jpeg');
        const scene = { background: photoUrl, products: [] };
        store.dispatch('addScene', scene);
    }
}

defineExpose({
    closeCamera,
    takePhoto
})

// 监听 isCapture 的变化
watch(() => props.isCapture, (newVal) => {
  if (!newVal) {
    closeCamera();
  }
});
</script>

<style scoped>
.photo-warp{width: 100%;height: 100%;position: relative;overflow: hidden;}
.photo-warp video{position: absolute;top: 0;left: 0;width: 100%;height: 100%;object-fit: cover;}
.photo-content{width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;}
.photo-content i {color: #2a2a2a;font-size: 10rem;}
</style>