<template>
	<div>
		<div class="search-box">
            <input type="text" v-model="searchText" autocomplete="off" placeholder="搜索商品">
            <span class="iconfont icon-search" @click="fetchProductList()"></span>
        </div>
        <div class="category-box">
            <div class="item" v-for="(item, index) in categoryList" :key="index" :class="{ active: categoryId === item.id }" @click="changeCategory(item.id)">{{ item.name }}</div>
        </div>
        <div class="product-list">
            <div class="item" v-for="(item, index) in productList" :key="index" @click.prevent="handleRowClick(item)">
                <div class="product-img col-8">
                    <img :src="item.image">
                </div>
                <div class="product-info col-16" style="padding: 0 10px;">
                    <div class="product-name">{{ item.product_name }}</div>
                    <div class="product-tag">
                        <span v-for="(tag, index) in item.tags" :key="index">{{ tag }}</span>
                    </div>
                </div>
            </div>
        </div>
	</div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { getProductList } from '@/api/product'

const searchText = ref('');

const categoryId = ref(1);

const categoryList = ref([
    { id: 1, name: '开关' },
    { id: 2, name: '灯具' },
    { id: 3, name: '音乐' },
    { id: 4, name: '窗帘' }
])

const productList = ref([]);

const changeCategory = (id) => {
    categoryId.value = id;
    fetchProductList();
}

const fetchProductList = () => {
    const params = {
        category_id: categoryId.value,
        product_name: searchText.value
    };
    getProductList(params).then((res) => {
        productList.value = res.data.list;
    })
}

const emit = defineEmits(['row-click']);

const handleRowClick = (item) => {
	emit('row-click', item);
}

onMounted(() => {
	fetchProductList();
})
</script>

<style scoped>
.search-box {position: relative;width: 100%;height: 3rem;border: 1px solid #ffffff;border-radius: 0.5rem;padding: 0 1rem;margin-bottom: 1rem;flex-shrink: 0;}
.search-box input {width: 100%;height: 100%;color: #ccc;border: none;background-color: transparent;font-size: 1rem;}
.search-box span {position: absolute;right: 0;width: 2.5rem;line-height: 3rem;text-align: center;height: 100%;color: #ccc;font-size: 1.2rem;}
.category-box {display: flex;justify-content: space-between;align-items: center;border-radius: 0.5rem;background-color: #cccccc;flex-shrink: 0;}
.category-box .item {padding: 0 2rem;margin-right: 0.5rem;font-weight: bold;line-height: 3rem;font-size: 1rem;}
.category-box .item.active{color: #f9b817;background-color: rgba(0, 0, 0, 0.5);border-radius: 0.5rem;}
.category-box .item:last-child {margin-right: 0;}
.product-list {height: calc(100vh - 17rem);overflow-y: auto;}
.product-list .item {display: flex;border-bottom: 1px dashed #ccc;padding: 1.8rem 0;}
.product-list .product-img img {max-width: 100%;}
.product-list .product-name {color: #ffffff;margin-bottom: 0.5rem;font-size: 1rem;}
.product-list .product-tag {color: #ffffff;display: flex;flex-wrap: wrap;}
.product-list .product-tag span {color: #ffffff;font-size: 0.875rem;border: 1px solid #ffffff;padding: 0.25rem 0.5rem;margin-right: 0.5rem;margin-bottom: 0.5rem;}

/* 滚动条样式 */
.product-list::-webkit-scrollbar {width: 6px;height: 6px;}
.product-list::-webkit-scrollbar-track {background: #f1f1f1;border-radius: 3px;}
.product-list::-webkit-scrollbar-thumb {background: #888;border-radius: 3px;}
.product-list::-webkit-scrollbar-thumb:hover {background: #555;}

/* 响应式布局 */
@media screen and (max-width: 1440px) {
    .category-box .item {padding: 0 1.5rem;}
}

@media screen and (max-width: 1024px) {
    .search-box { height: 2.5rem;}
    .search-box span {line-height: 2.5rem;}
    .category-box {height: 2.5rem;}
    .category-box .item {line-height: 2.5rem;}
}

@media screen and (max-width: 768px) {
    .product-list .item {padding: 1.5rem 0;}
}
</style>